import { useTranslation } from 'react-i18next'
import styles from '../User.module.scss'
import { useStoreSelector } from 'hooks/store'
import { selectContact, selectCurrentAccount } from 'store/contact/selectors.ts'
import { Heading } from '@boltenergy-be/design-system'
import dayjs from 'dayjs'
import { DATE_FORMAT } from 'constants/constants'
import Link from 'components/Link/Link.tsx'
import { routes } from 'types/routes'
import { UserEvents } from 'types/tracking.ts'
import mixpanel from 'mixpanel-browser'
import { UserTypes } from 'store/auth/types'
import classNames from 'classnames'

const Personal = () => {
  // Redux
  const contactStore = useStoreSelector((store) => store.contact)
  const contact = selectContact(contactStore)
  const account = selectCurrentAccount(contactStore)
  const { userType } = useStoreSelector((store) => store.auth)

  // i18n
  const { t } = useTranslation(['user', 'validation', 'contracts', 'common'])

  // Constants
  const birthDate = account.birthDate

  return (
    <section className={styles['table-wrapper']}>
      <div>
        {!!account.company && (
          <Heading as="h2" variant="h5">
            {t('common:personal')}
          </Heading>
        )}

        <ul className={styles.table}>
          <li>
            <strong>{t('user:personal.form.clientNbr')}</strong>
            {account.customerNumber || '—'}
          </li>

          <li>
            <strong>{t('user:personal.form.firstName')}</strong>
            {account.firstName || '—'}
          </li>

          <li>
            <strong>{t('user:personal.form.lastName')}</strong>
            {account.lastName || '—'}
          </li>

          <li>
            <strong>{t('user:personal.form.email')}</strong>
            {contact?.email || '—'}
          </li>

          <li>
            <strong>{t('user:personal.form.phone')}</strong>
            {contact?.phone || '—'}
          </li>

          <li>
            <strong>{t('user:personal.form.birthDate.title')}</strong>
            {birthDate ? dayjs(birthDate).format(DATE_FORMAT) : '—'}
          </li>
        </ul>
      </div>

      {!!account.company && (
        <div className={styles.company}>
          <Heading as="h2" variant="h5">
            {t('contracts:add.steps.customerData.sections.company')}
          </Heading>
          <ul className={styles.table}>
            <li>
              <strong>{t('user:personal.form.company.name')}</strong>
              {account.company.name || '—'}
            </li>

            <li>
              <strong>{t('user:personal.form.company.legalForm')}</strong>
              {account.company.legalForm ? t(`common:legalForms.${account.company.legalForm}`) : '—'}
            </li>

            <li>
              <strong>{t('user:personal.form.company.enterpriseNumber')}</strong>
              {account.company.enterpriseNumber || '—'}
            </li>
          </ul>
        </div>
      )}

      {userType === UserTypes.SUPER_USER ? (
        <Link
          representation="button"
          isFullwidthMobile
          className={styles.edit}
          variant="secondary"
          href={routes.USER_EDIT_PERSONAL}
          onClick={() => mixpanel.track(UserEvents.GO_TO_EDIT_PERSONAL_INFO)}
        >
          {t('common:edit')}
        </Link>
      ) : (
        <footer className={classNames(styles.footer, 'grid-col-full')}>
          <small>{t('support')}</small>
          <Link href={routes.SUPPORT_CONTACT} onClick={() => mixpanel.track(UserEvents.GO_TO_CONTACT)} variant="secondary" size="small">
            {t('contactTab', { ns: 'support' })}
          </Link>
        </footer>
      )}
    </section>
  )
}

export default Personal
