import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PaymentFormInputs } from '../types'
import { useStoreDispatch, useStoreSelector } from 'hooks/store'
import { Banner, Button, Form, InlineMessage, TextField, Toggle, popSuccessToast } from '@boltenergy-be/design-system'
import { ContractResponseCodes } from 'types/errorCodes.ts'
import { removeNonAlphanumericals } from 'utils/format.ts'
import { selectCurrentAccount } from 'store/contact/selectors.ts'
import { updateAccount } from 'store/contact/thunks'
import { ThunkDefaultErrorMsg } from 'store/contact/thunks/types.ts'
import styles from './PaymentForm.module.scss'
import mixpanel from 'mixpanel-browser'
import { UserEvents } from 'types/tracking.ts'
import { ALLOWED_IBAN_COUNTRIES } from 'features/contracts/add/constants'
import IBAN from 'iban'
import { useNavigate } from 'react-router'
import { routes } from 'types/routes.ts'

const PaymentForm = () => {
  // Redux
  const dispatch = useStoreDispatch()
  const contactStore = useStoreSelector((store) => store.contact)
  const { error, loading } = contactStore
  const { paymentDetails, contactSfId, sfId } = selectCurrentAccount(contactStore)

  // i18n
  const { t } = useTranslation('user')

  // Router
  const navigate = useNavigate()

  // Constants
  const mandateNumber = paymentDetails?.mandateNumber

  // React hook form
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors }
  } = useForm<PaymentFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      directDebitPaymentMode: paymentDetails?.directDebit,
      iban: paymentDetails?.iban
    }
  })
  const watchDirectDebitPaymentMode = watch('directDebitPaymentMode')

  /**
   * Handles the form submission after validation by React Hook Form
   *
   * @param {PaymentFormInputs} data
   */
  const onSubmit = async (data: PaymentFormInputs) => {
    // Update the account (only map the fields that have changed since it's a PATCH request)
    const res = await dispatch(
      updateAccount({
        accountId: sfId,
        contactId: contactSfId,
        account: {
          paymentDetails: {
            iban: data.iban,
            directDebit: data.directDebitPaymentMode
          }
        }
      })
    )

    if (res.meta.requestStatus === 'fulfilled') {
      navigate(routes.USER_PAYMENT)
      popSuccessToast(t('formFeedback.saved', { ns: 'common' }))
    }

    mixpanel.track(UserEvents.SUBMIT_EDIT_PAYMENT_INFO)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles['payment-form']}>
      <Banner type="informative">{t('payment.banner')}</Banner>

      <div className={styles['toggle-wrapper']}>
        <label>{t('payment.fields.paymentMethod.title')}</label>
        <Controller
          name="directDebitPaymentMode"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Toggle
              isFullwidth
              active={value}
              onClick={onChange}
              options={[
                { value: false, label: t('payment.fields.paymentMethod.bankTransfer') },
                { value: true, label: t('payment.fields.paymentMethod.directDebit') }
              ]}
            />
          )}
        />
      </div>

      <TextField
        id="iban"
        label={t('add.steps.customerData.fields.iban', { ns: 'contracts' })}
        description={t('payment.fields.financial.description')}
        {...register('iban', {
          required: t('required', { ns: 'validation' }),
          validate: {
            valid: (value) => (value && IBAN.isValid(value)) || t('invalid.iban', { ns: 'validation' }),
            allowed: (value) =>
              (value && ALLOWED_IBAN_COUNTRIES.includes(value.slice(0, 2))) || t('invalid.ibanCountries', { ns: 'validation' })
          }
        })}
        onPaste={(e) => {
          const paste = removeNonAlphanumericals(e.clipboardData.getData('text'))
          e.currentTarget.value = paste
          setValue('iban', paste)
          e.preventDefault()
        }}
        error={errors?.iban?.message}
        grid="full"
      />

      {watchDirectDebitPaymentMode && (
        <TextField label={t('payment.fields.financial.mandate')} disabled name="mandate" value={mandateNumber ?? '/'} />
      )}

      <Button isFullwidth type="submit" disabled={Object.keys(errors).length > 0} loading={loading}>
        {t('save', { ns: 'common' })}
      </Button>

      {!loading &&
        error &&
        [ThunkDefaultErrorMsg.UPDATE_ACCOUNT as string, ContractResponseCodes.MERCATOR_CUSTOMER_NOT_FOUND].includes(error) && (
          <InlineMessage type="negative">
            {error === ContractResponseCodes.MERCATOR_CUSTOMER_NOT_FOUND
              ? t('payment.errors.mercatorErrorNotFound', { ns: 'user' })
              : t('error', { ns: 'common' })}
          </InlineMessage>
        )}
    </Form>
  )
}

export default PaymentForm
